import React, { useState } from 'react';

// Define the list of animal emojis
const animalEmojis = ['🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵'];

// RandomAnimalButton component
const RandomAnimalButton: React.FC = () => {
  // State to store the current emoji
  const [currentEmoji, setCurrentEmoji] = useState<string>('');

  // Function to handle button click
  const handleClick = () => {
    // Randomly select an emoji from the list
    const randomIndex = Math.floor(Math.random() * animalEmojis.length);
    const selectedEmoji = animalEmojis[randomIndex];
    
    // Update the current emoji state
    setCurrentEmoji(selectedEmoji);
    
    // Display the selected emoji in an alert
    alert(`Random Animal Emoji: ${selectedEmoji}`);
  };

  // Styles for the button
  const buttonStyle: React.CSSProperties = {
    backgroundColor: 'green',
    color: 'white',
    margin: '0 auto',
    border: '1px solid white',
    padding: '5px 7px',
    display: 'block', // Ensures the button is a block-level element
    cursor: 'pointer',
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      Show Random Animal {currentEmoji}
    </button>
  );
};

export default RandomAnimalButton;